import React from 'react';
import './DateInput.css'; // Import the CSS file

const DateInput = ({ id, value, onChange, minDate, label }) => {
  return (
    <div className="date-input-container">
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type="date"
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        min={minDate}
      />
    </div>
  );
};

export default DateInput;
