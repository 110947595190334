import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AirportAutocomplete from './AirportAutocomplete';
import DateInput from './DateInput';
import TravelersModal from './TravelersModal';
import Loading from '../Loading/Loading';
import './Bookingslanding.css';

const Booking = () => {
  const [tripType, setTripType] = useState('roundTrip');
  const [originCode, setOriginCode] = useState('');
  const [destinationCode, setDestinationCode] = useState('');
  const [departDate, setDepartDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [travelersInfo, setTravelersInfo] = useState({
    adults: 1,
    children: 0,
    infants: 0,
    cabinClass: 'economy',
    displayText: '1 Traveler, Economy',
  });
  const [showTravelersModal, setShowTravelersModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const today = new Date().toISOString().split('T')[0];

  const validateForm = () => {
    if (!originCode || !destinationCode || !departDate || (tripType === 'roundTrip' && !returnDate)) {
      setErrorMessage('Please fill in all required fields.');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', clearLocalStorage);
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
      clearLocalStorage();
    };
  }, []);

  const clearLocalStorage = () => {
    localStorage.removeItem('selectedArrivalFlight');
    localStorage.removeItem('selectedDepartureFlight');
    localStorage.removeItem('selectedFlight');
    localStorage.removeItem('paymentInfo');
    localStorage.removeItem('userData');
  };

  const handleSearch = async () => {
    if (!validateForm()) return;

    setLoading(true);
    const url = 'https://flight-fare-search.p.rapidapi.com/v2/flights/';
    const requestData = {
      from: originCode,
      to: destinationCode,
      date: departDate,
      adult: travelersInfo.adults,
      child: travelersInfo.children,
      infant: travelersInfo.infants,
      currency: 'USD',
      ...(tripType === 'roundTrip' && { returnDate }),
    };

    localStorage.setItem('requestData', JSON.stringify(requestData));

    try {
      const searchParams = new URLSearchParams(requestData);
      const searchURL = `${url}?${searchParams}`;
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '1f005ee062msh1e836e400a98804p178daajsnec9f4f2a6899',
          'X-RapidAPI-Host': 'flight-fare-search.p.rapidapi.com',
        },
      };

      const response = await fetch(searchURL, options);
      if (!response.ok) throw new Error('Failed to fetch flight data');

      const data = await response.json();

      if (tripType === 'roundTrip') {
        const arrivalSearchParams = {
          ...requestData,
          from: requestData.to,
          to: requestData.from,
        };
        const arrivalSearchURL = `${url}?${new URLSearchParams(arrivalSearchParams)}`;
        const arrivalResponse = await fetch(arrivalSearchURL, options);
        const arrivalData = await arrivalResponse.json();

        navigate(`/depart`, {
          state: { searchData: requestData, results: data.results, returnResults: arrivalData.results },
        });
      } else {
        navigate(`/search_results`, { state: { searchData: requestData, results: data.results } });
      }
    } catch (error) {
      setErrorMessage('Failed to fetch flight data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="booking-container">
      {loading ? (
        <Loading />
      ) : (

        

        
        <div className="booking-wrapper">
          <div className="booking-title">Search , Compare , Book airline tickets with VGroup Ventures LLC</div>


<div className="home-call-class-content">
        <div className='booking-title'>Call now & Get up to 15 % off on flights</div>
        <a href="tel:+18664104797" className="home-call-class-button">
      Toll Free : +1(866)410-4797
        </a>
      </div>



          <div className="booking-card">
            <div className="booking-content">
              {/* Trip Type Selection */}
              <div className="trip-type">
                <label className="radio-label">
                  <input
                    type="radio"
                    name="tripType"
                    value="roundTrip"
                    checked={tripType === 'roundTrip'}
                    onChange={(e) => setTripType(e.target.value)}
                  />
                  Round Trip
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    name="tripType"
                    value="oneWay"
                    checked={tripType === 'oneWay'}
                    onChange={(e) => setTripType(e.target.value)}
                  />
                  One Way
                </label>
              </div>

              {/* Main Search Form */}
              <div className="search-grid">
                {/* Origin */}
                <div className="input-group">
                  <span className="input-icon">📍</span>
                  <AirportAutocomplete
                    id="origin"
                    value={originCode}
                    onChange={setOriginCode}
                  />
                </div>

                {/* Destination */}
                <div className="input-group">
                  <span className="input-icon">📍</span>
                  <AirportAutocomplete
                    id="destination"
                    value={destinationCode}
                    onChange={setDestinationCode}
                  />
                </div>

                {/* Date Selection */}
                <div className="date-group">
                  <div className="input-group">
                    <span className="input-icon">📅</span>
                    <DateInput
                      id="departDate"
                      value={departDate}
                      onChange={setDepartDate}
                      minDate={today}
                      label="Departure Date"
                    />
                  </div>
                  {tripType === 'roundTrip' && (
                    <div className="input-group">
                      <span className="input-icon">📅</span>
                      <DateInput
                        id="returnDate"
                        value={returnDate}
                        onChange={setReturnDate}
                        minDate={departDate || today}
                        label="Return Date"
                      />
                    </div>
                  )}
                </div>

                {/* Travelers & Search */}
                <div className="action-group">
                  <div className="input-group">
                    <span className="input-icon">👤</span>
                    <input
                      type="text"
                      className="search-input"
                      value={travelersInfo.displayText}
                      onClick={() => setShowTravelersModal(true)}
                      readOnly
                    />
                  </div>

                  <TravelersModal
                    isOpen={showTravelersModal}
                    onClose={() => setShowTravelersModal(false)}
                    onSave={(newTravelersInfo) => {
                      setTravelersInfo(newTravelersInfo);
                      setShowTravelersModal(false);
                    }}
                    initialValues={travelersInfo}
                  />
                  <button
                    className="search-button"
                    onClick={handleSearch}
                    disabled={
                      !originCode ||
                      !destinationCode ||
                      !departDate ||
                      (tripType === 'roundTrip' && !returnDate)
                    }
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          

          {/* Trust Badges */}
          {/* <div className="trust-badges">
            <p>Trusted by 100,000+ customers every year</p>
            <div className="ratings">
              <div className="rating-group">
                <span>Excellent</span>
                <div className="stars">★★★★★</div>
                <span>(4.8) 5,866 Reviews on Trustpilot</span>
              </div>
              <div className="separator"></div>
              <div className="rating-group">
                <span>Excellent</span>
                <div className="stars">★★★★★</div>
                <span>(4.8) 11,980 Reviews on Sitejabber</span>
              </div>
            </div>
          </div> */}
        </div>
        
      )}

      
    </div>
  );
};

export default Booking;
