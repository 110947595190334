import React from "react";
import "./NewBooking.css";

const NewBooking = () => {
  return (
    <div className="new-booking-page-container">
      {/* Header Section */}
      <div className="new-booking-page-header">
        <h1>Experience Seamless Travel Booking</h1>
        <h2>Cheap Flights Just a Click Away</h2>
      </div>

      {/* Tabs Section */}
      <div className="new-booking-page-tabs">
        <button className="new-booking-page-tab active">Flights</button>
        <button className="new-booking-page-tab">Cars</button>
        <button className="new-booking-page-tab">Hotels</button>
        <button className="new-booking-page-tab">Airport Transfer</button>
      </div>

      {/* Search Box */}
      <div className="new-booking-page-search-box">
        {/* Trip Options */}
        <div className="new-booking-page-options">
          <label className="new-booking-page-radio">
            <input type="radio" name="tripType" checked />
            Round Trip
          </label>
          <label className="new-booking-page-radio">
            <input type="radio" name="tripType" />
            One Way
          </label>
        </div>

        {/* Input Fields */}
        <div className="new-booking-page-inputs">
          <input
            type="text"
            placeholder="Origin"
            className="new-booking-page-input"
            value="LKO - Chaudhary Charan Singh Int’l"
            readOnly
          />
          <input
            type="text"
            placeholder="Destination"
            className="new-booking-page-input"
            value="GOP - Gorakhpur, Gorakhpur"
            readOnly
          />
          <input
            type="date"
            className="new-booking-page-input"
            value="2024-12-21"
          />
          <input
            type="date"
            className="new-booking-page-input"
            value="2024-12-25"
          />
          <select className="new-booking-page-input">
            <option>1 Traveler, Economy</option>
          </select>
        </div>

        {/* Search Button */}
        <button className="new-booking-page-search-button">Search</button>
      </div>

      {/* Footer Section */}
      <div className="new-booking-page-footer">
        <p>Trusted by 100,000+ customers every year</p>
        <div className="new-booking-page-reviews">
          <span>
            Excellent <span className="new-booking-page-stars">★★★★★</span>{" "}
            (4.4) 5,859 Reviews on Trustpilot
          </span>
          <span>
            <span className="new-booking-page-stars">★★★★★</span> (4.8) 11,980
            Reviews on Sitejabber
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewBooking;
