import React, { useState } from 'react';
import './NumberPopup.css';
import call from '../../Media/call.png'; // Phone call icon
import caller from '../../Media/Girl_cruise1.png'; // Agent icon

const NumberPopup = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handlePhoneClick = () => {
        // Google Ads conversion tracking for phone calls
        window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
          'phone_conversion_number': '(866) 410-4797'
        });
      
        // Initiate the phone call
        window.location.href = 'tel:+18664104797';
      };

    if (!isVisible) {
        return null;
    }

    return (
        <a href="tel:+18664104797" className="bottom-bar">
            <a href="tel:+18664104797"  className="popup-content">
                <a className="small-text">Call Now & Get up to<span className="discount-box">15% OFF</span>on Flights</a>
                
                    <a href="tel:+18664104797" onClick={handlePhoneClick} className="phone-number">(866) 410-4797</a>
            </a>
            
        </a>
    );
};

export default NumberPopup;
