import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPlaneDeparture, FaPlaneArrival } from 'react-icons/fa';
import LoadingComponent from '../Loading/Loading';
import '../SearchResults/SearchResults.css'; // Assuming this is the correct path to your CSS file
import './RoundTripSearch.css'
import Loading from '../Loading/Loading';

const DepartureSearch = () => {
    const location = useLocation();
    const searchData = location.state ? location.state.searchData : null;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [departureResults, setDepartureResults] = useState([]);
    const [arrivalResults, setArrivalResults] = useState([]);
    const [selectedDepartureFlight, setSelectedDepartureFlight] = useState(null);
    const [selectedArrivalFlight, setSelectedArrivalFlight] = useState(null);
    const navigate = useNavigate();



    useEffect(() => {
        if (!searchData) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                const url = 'https://flight-fare-search.p.rapidapi.com/v2/flights/';
                const options = {
                    method: 'GET',
                    headers: {
                        'X-RapidAPI-Key': '1f005ee062msh1e836e400a98804p178daajsnec9f4f2a6899',
                        'X-RapidAPI-Host': 'flight-fare-search.p.rapidapi.com'
                    }
                };

                // Departure request
                const departureRequestData = {
                    from: searchData.from,
                    to: searchData.to,
                    date: searchData.date,
                    adult: searchData.adult,
                    child: searchData.child,
                    infant: searchData.infant,
                    currency: searchData.currency
                };
                const departureSearchParams = new URLSearchParams(departureRequestData);
                const departureSearchURL = `${url}?${departureSearchParams}`;
                const departureResponse = await fetch(departureSearchURL, options);
                if (!departureResponse.ok) {
                    throw new Error('Failed to fetch departure flight data');
                }
                const departureData = await departureResponse.json();
                setDepartureResults(departureData.results);

                // Arrival request
                const arrivalRequestData = {
                    from: searchData.to,
                    to: searchData.from,
                    date: searchData.returnDate,
                    adult: searchData.adult,
                    child: searchData.child,
                    infant: searchData.infant,
                    currency: searchData.currency
                };
                const arrivalSearchParams = new URLSearchParams(arrivalRequestData);
                const arrivalSearchURL = `${url}?${arrivalSearchParams}`;
                const arrivalResponse = await fetch(arrivalSearchURL, options);
                if (!arrivalResponse.ok) {
                    throw new Error('Failed to fetch arrival flight data');
                }
                const arrivalData = await arrivalResponse.json();
                setArrivalResults(arrivalData.results);

                setLoading(false);
            } catch (error) {
                setError('Failed to fetch flight data. Please try again later.');
                setLoading(false);
            }
        };

        fetchData();
    }, [searchData]);
    
    const [activeSection, setActiveSection] = useState('departure');

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };





    // Function to handle selection of departure flight
    const handleSelectDepartureFlight = (flight) => {
        setSelectedDepartureFlight(flight); // Update selected departure flight in the component's state
        localStorage.setItem('selectedDepartureFlight', JSON.stringify(flight)); // Store selected departure flight in localStorage
    };




    // Function to handle selection of arrival flight
    const handelSelectArrivalFlight = (flight) => {
        setSelectedArrivalFlight(flight); // Update selected arrival flight in the component's state
        localStorage.setItem('selectedArrivalFlight', JSON.stringify(flight)); // Store selected arrival flight in localStorage
    };



    // Calculate total price
    const totalPrice =
        selectedDepartureFlight && selectedArrivalFlight
            ? Math.round(
                (selectedDepartureFlight.totals.total + selectedArrivalFlight.totals.total) * 10
            ) / 10
            : null;




    const handleContinue = () => {
        if (selectedDepartureFlight && selectedArrivalFlight) {
            // Both departure and arrival flights are selected, proceed to continue
            navigate('/user_details_form_round_trip');
        } else {
            // Display an error message or handle the case where flights are not selected
        }
    };

    // Define a function to format time
    const formatTime = (timeString) => {
        const time = new Date(timeString);
        return time.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });
    };


    const handlePhoneClick = () => {
        // Google Ads conversion tracking for phone calls
        window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
            'phone_conversion_number': '(509) 517-7230'
          });
        };
      





    if (loading) {
        return <div><Loading /></div>;
    }
    if (error) {
        return <div>Sorry, something went wrong. Please fix the following:</div>;
    }

    if (!departureResults || departureResults.length === 0 || !arrivalResults || arrivalResults.length === 0) {
        navigate('/no-flights-found');
        return null; // Render nothing because we're redirecting
    }

    

    return (

        <div className="main-container">

<div className="heading-container oneway-filter-heading-container">
        <div className='flight-cards-container oneway-filter-flight-cards-container enhanced-section'>
        <p>
            Call Now for Exclusive Discounted Flight Deals
            <a
       href="tel:+18664104797"
      onClick={handlePhoneClick}
      className="highlighted-number"
    >
      (866) 410-4797
    </a>
          </p>
        </div>
      </div>
            <div className="flight-section-tabs">
                <button
                    className={activeSection === 'departure' ? 'active' : ''}
                    onClick={() => handleSectionChange('departure')}
                >
                    Departure
                </button>
                <button
                    className={activeSection === 'arrival' ? 'active' : ''}
                    onClick={() => handleSectionChange('arrival')}
                >
                    Arrival
                </button>
            </div>
            <div className="flight-cards-container">
                <div className="row">
                    <div className="departure-cards" style={{ display: activeSection === 'departure' ? 'block' : 'none' }}>
                        {/* Render DepartureCard components here */}
                        <div className="departure-cards">
                            {departureResults ? (
                                departureResults.map((flight, index) => (
                                    <DepartureCard
                                        key={index}
                                        flight={flight}
                                        selectedDepartureFlight={selectedDepartureFlight} // Pass selected departure flight as prop
                                        onSelectDepartureFlight={handleSelectDepartureFlight} // Pass onSelectDepartureFlight function as prop
                                    />
                                ))
                            ) : (
                                <p>No departure results found</p>
                            )}
                        </div>
                    </div>
                    <div className="arrival-cards" style={{ display: activeSection === 'arrival' ? 'block' : 'none' }}>
                        {/* Render ArrivalCard components here */}
                        <div className="arrival-cards">
                            {arrivalResults ? (
                                arrivalResults.map((flight, index) => (
                                    <ArrivalCard
                                        key={index}
                                        flight={flight}
                                        selectedArrivalFlight={selectedArrivalFlight}
                                        onSelectArrivalFlight={handelSelectArrivalFlight} // Pass onSelectArrivalFlight function as prop
                                    />

                                ))
                            ) : (
                                <p>No arrival results found</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            <div className='bottom_card'>
                {selectedDepartureFlight && selectedArrivalFlight && (
                    <div className="selected-flights-container">
                        <div className="flight-details_sect">
                            <div className='left'>
                                <p className="airline-name">Departure: {selectedDepartureFlight.flight_name}</p>
                                <div className="flight-times">
                                    <div>
                                        <p>{selectedDepartureFlight.departureAirport.city}</p>
                                        <p> {formatTime(selectedDepartureFlight.departureAirport.time)}</p>
                                    </div>
                                    <div className="arrow-icon">&#8594;</div>
                                    <div>
                                        <p>{selectedDepartureFlight.arrivalAirport.city}</p>
                                        <p> {formatTime(selectedDepartureFlight.arrivalAirport.time)}</p>
                                    </div>
                                </div>
                                {/* <p className="flight-price">${selectedDepartureFlight.totals.total.toFixed(2)}</p> */}
                            </div>
                            <div className='right'>
                                <p className="airline-name">Departure: {selectedArrivalFlight.flight_name}</p>
                                <div className="flight-times">
                                    <div>
                                        <p>{selectedArrivalFlight.departureAirport.city}</p>
                                        <p>{formatTime(selectedArrivalFlight.departureAirport.time)}</p>
                                    </div>
                                    <div className="arrow-icon">&#8594;</div>
                                    <div>
                                        <p>{selectedArrivalFlight.arrivalAirport.city}</p>
                                        <p>{formatTime(selectedArrivalFlight.arrivalAirport.time)}</p>
                                    </div>
                                </div>
                                {/* <p className="flight-price">${selectedArrivalFlight.totals.total.toFixed(2)}</p> */}
                            </div>
                        </div>
                        <div className="flight-details_sect">
                            <p className="total-price">Total Price: ${totalPrice.toFixed(2)}</p>
                        </div>
                        <button onClick={handleContinue}>Continue</button>
                    </div>
                )}


            </div>


        </div>

    );
};

const airlineLogoMapping = {
    "Air India": "https://airlabs.co/img/airline/m/AI.png",
    "Vistara": "https://airlabs.co/img/airline/m/UK.png",
    "Air India Express": "https://airlabs.co/img/airline/m/IX.png",
};

const getAirlineLogoUrl = (airlineName) => {
    return airlineLogoMapping[airlineName] || "https://airlabs.co/img/airline/m/6E.png";
};

const DepartureCard = ({ flight, selectedDepartureFlight, onSelectDepartureFlight, onSelectArrivalFlight, selectedArrivalFlight }) => {


    const {
        flight_name,
        departureAirport,
        arrivalAirport,
        duration,
        totals,
        stops,
        flight_code,
    } = flight;

    const departureDate = new Date(departureAirport.time);
    const formattedDepartureDate = departureDate.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
    });

    const arrivalDate = new Date(arrivalAirport.time);
    const formattedArrivalDate = arrivalDate.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
    });

    const formattedDepartureTime = departureDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });

    const formattedArrivalTime = arrivalDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });

    const airlineLogoMapping = {
        "Air India": "https://airlabs.co/img/airline/m/AI.png",
        "Vistara": "https://airlabs.co/img/airline/m/UK.png",
        "Air India Express": "https://airlabs.co/img/airline/m/IX.png",
    };

    const getAirlineLogoUrl = (airlineName) => {
        return airlineLogoMapping[airlineName] || "https://airlabs.co/img/airline/m/6E.png";
    };

    const totalPrice = Math.round(totals.total * 10) / 10;
    const departureLocation = `${departureAirport.code} - ${departureAirport.city}`;
    const arrivalLocation = `${arrivalAirport.code} - ${arrivalAirport.city}`;
    const airlineLogoUrl = getAirlineLogoUrl(flight_name);



    const handleSelectFlight = () => {
        onSelectDepartureFlight(flight); // Update selected departure flight
        if (selectedArrivalFlight) {
            onSelectArrivalFlight(selectedArrivalFlight); // Update selected arrival flight if already selected
        }
    };
    return (
        <div className="flight-card">
            <div className="flight-logo_s">
                <div className="flight-logo"><img src={airlineLogoUrl} alt="" /></div>
                <div className='f_name'><span>{flight_name}</span></div>
                <div className='f_name'>{flight_code}</div>
            </div>
            <div className="flight-details">
                <div className='data_fli'>
                    <div className='line'>
                        <FaPlaneDeparture className="icon" />
                        <hr className="separator" />
                        <FaPlaneArrival className="icon" />
                    </div>
                    <div className='flight-path'>
                        <div className="from">
                            <span><p>{departureLocation}</p></span>
                            <span>{formattedDepartureDate}</span>
                            <span>{formattedDepartureTime}</span>
                        </div>
                        <div className="animated-flight-path">
                            <div className="stops">
                                <span>{stops === "Direct" ? "Direct" : `${stops} stops`}</span>
                            </div>
                            <hr />
                            <div className="c">
                                <span>{duration.text}</span>
                            </div>
                        </div>
                        <div className="to">
                            <span><p>{arrivalLocation}</p></span>
                            <span>{formattedArrivalDate}</span>
                            <span>{formattedArrivalTime}</span>
                        </div>
                    </div>
                </div>
                <div className="price">
                    <span className="price-label">Price</span>
                    <span className="price-value">${totalPrice}</span>
                    <input
                        type="radio"
                        name="selectedFlight"
                        onClick={handleSelectFlight}
                    />
                </div>
            </div>
        </div>
    );
};





const ArrivalCard = ({ flight, selectedArrivalFlight, onSelectArrivalFlight, onSelectDepartureFlight, selectedDepartureFlight }) => {

    const {
        flight_name,
        departureAirport,
        arrivalAirport,
        duration,
        totals,
        stops,
        flight_code,
    } = flight;

    const departureDate = new Date(departureAirport.time);
    const formattedDepartureDate = departureDate.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
    });

    const arrivalDate = new Date(arrivalAirport.time);
    const formattedArrivalDate = arrivalDate.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
    });

    const formattedDepartureTime = departureDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });

    const formattedArrivalTime = arrivalDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });

    const airlineLogoMapping = {
        "Air India": "https://airlabs.co/img/airline/m/AI.png",
        "Vistara": "https://airlabs.co/img/airline/m/UK.png",
        "Air India Express": "https://airlabs.co/img/airline/m/IX.png",
    };

    const getAirlineLogoUrl = (airlineName) => {
        return airlineLogoMapping[airlineName] || "https://airlabs.co/img/airline/m/6E.png";
    };

    const totalPrice = Math.round(totals.total * 10) / 10;
    const departureLocation = `${departureAirport.code} - ${departureAirport.city}`;
    const arrivalLocation = `${arrivalAirport.code} - ${arrivalAirport.city}`;
    const airlineLogoUrl = getAirlineLogoUrl(flight_name);
    const navigate = useNavigate();

    // const handleBookNow = (flight) => {
    //     localStorage.setItem('selectedFlight', JSON.stringify(flight));
    //     navigate('/user_details_form');
    // };

    const handleSelectFlight = () => {
        onSelectArrivalFlight(flight); // Update selected arrival flight
        if (selectedDepartureFlight) {
            onSelectDepartureFlight(selectedDepartureFlight); // Update selected departure flight if already selected
        }
    };




    return (
        <div className="flight-card">
            <div className="flight-logo_s">
                <div className="flight-logo"><img src={airlineLogoUrl} alt="" /></div>
                <div className='f_name'><span>{flight_name}</span></div>
                <div className='f_name'>{flight_code}</div>
            </div>
            <div className="flight-details">
                <div className='data_fli'>
                    <div className='line'>
                        <FaPlaneDeparture className="icon" />
                        <hr className="separator" />
                        <FaPlaneArrival className="icon" />
                    </div>
                    <div className='flight-path'>
                        <div className="from">
                            <span><p>{departureLocation}</p></span>
                            <span>{formattedDepartureDate}</span>
                            <span>{formattedDepartureTime}</span>
                        </div>
                        <div className="animated-flight-path">
                            <div className="stops">
                                <span>{stops === "Direct" ? "Direct" : `${stops} stops`}</span>
                            </div>
                            <hr />
                            <div className="c">
                                <span>{duration.text}</span>
                            </div>
                        </div>
                        <div className="to">
                            <span><p>{arrivalLocation}</p></span>
                            <span>{formattedArrivalDate}</span>
                            <span>{formattedArrivalTime}</span>
                        </div>
                    </div>
                </div>
                <div className="price">
                    <span className="price-label">Price</span>
                    <span className="price-value">${totalPrice}</span>
                    <input
                        type="radio"
                        name="selectedFlight"
                        onClick={handleSelectFlight}
                    />

                </div>
            </div>
        </div>
    );
};
export default DepartureSearch;
