import React, { useState } from 'react';
import './TravelersModal.css'

const TravelersModal = ({ isOpen, onClose, onSave, initialValues }) => {
  const [adults, setAdults] = useState(initialValues?.adults || 1);
  const [children, setChildren] = useState(initialValues?.children || 0);
  const [infants, setInfants] = useState(initialValues?.infants || 0);
  const [cabinClass, setCabinClass] = useState(initialValues?.cabinClass || 'Economy');

  const handleIncrement = (setter, value, max) => {
    if (value < max) {
      setter(value + 1);
    }
  };

  const handleDecrement = (setter, value, min) => {
    if (value > min) {
      setter(value - 1);
    }
  };

  const handleSave = () => {
    onSave({
      adults,
      children,
      infants,
      cabinClass,
      displayText: `${adults + children + infants} Traveler${adults + children + infants > 1 ? 's' : ''}, ${cabinClass}`
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="travelers-modal">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Traveler(s), Cabin</h3>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>

        <div className="modal-body">
          {/* Adults Section */}
          <div className="traveler-section">
            <div className="traveler-info">
              <div>
                <h4>Adult(s)</h4>
                <span>12+ y.o</span>
              </div>
              <div className="counter">
                <button 
                  className="counter-button"
                  onClick={() => handleDecrement(setAdults, adults, 1)}
                  disabled={adults <= 1}
                >
                  -
                </button>
                <span>{adults}</span>
                <button 
                  className="counter-button"
                  onClick={() => handleIncrement(setAdults, adults, 9)}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          {/* Children Section */}
          <div className="traveler-section">
            <div className="traveler-info">
              <div>
                <h4>Children</h4>
                <span>2-11 y.o.</span>
              </div>
              <div className="counter">
                <button 
                  className="counter-button"
                  onClick={() => handleDecrement(setChildren, children, 0)}
                  disabled={children <= 0}
                >
                  -
                </button>
                <span>{children}</span>
                <button 
                  className="counter-button"
                  onClick={() => handleIncrement(setChildren, children, 8)}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          {/* Infants Section */}
          <div className="traveler-section">
            <div className="traveler-info">
              <div>
                <h4>Infant(s) On Lap</h4>
                <span>Under 2 y.o.</span>
              </div>
              <div className="counter">
                <button 
                  className="counter-button"
                  onClick={() => handleDecrement(setInfants, infants, 0)}
                  disabled={infants <= 0}
                >
                  -
                </button>
                <span>{infants}</span>
                <button 
                  className="counter-button"
                  onClick={() => handleIncrement(setInfants, infants, 4)}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          {/* Cabin Class Selection */}
          <div className="cabin-class">
            <h4>Cabin Class</h4>
            <div className="cabin-options">
              <button 
                className={`cabin-button ${cabinClass === 'Economy' ? 'selected' : ''}`}
                onClick={() => setCabinClass('Economy')}
              >
                Economy
              </button>
              <button 
                className={`cabin-button ${cabinClass === 'Premium Economy' ? 'selected' : ''}`}
                onClick={() => setCabinClass('Premium Economy')}
              >
                Premium Economy
              </button>
              <button 
                className={`cabin-button ${cabinClass === 'Business' ? 'selected' : ''}`}
                onClick={() => setCabinClass('Business')}
              >
                Business
              </button>
              <button 
                className={`cabin-button ${cabinClass === 'First' ? 'selected' : ''}`}
                onClick={() => setCabinClass('First')}
              >
                First
              </button>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button className="done-button" onClick={handleSave}>Done</button>
        </div>
      </div>
    </div>
  );
};

export default TravelersModal;