import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import AirportAutocomplete from './AirportAutocomplete';
import DateInput from './DateInput';
import TravelersModal from './TravelersModal';
import './Bookingslanding.css';

// Constants moved outside component
const API_OPTIONS = {
  method: 'GET',
  headers: {
    'X-RapidAPI-Key': '1f005ee062msh1e836e400a98804p178daajsnec9f4f2a6899',
    'X-RapidAPI-Host': 'flight-fare-search.p.rapidapi.com',
  },
};

const DEFAULT_TRAVELERS_INFO = {
  adults: 1,
  children: 0,
  infants: 0,
  cabinClass: 'economy',
  displayText: '1 Traveler, Economy',
};

const API_URL = 'https://flight-fare-search.p.rapidapi.com/v2/flights/';

const Booking = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    tripType: 'roundTrip',
    originCode: '',
    destinationCode: '',
    departDate: '',
    returnDate: '',
    travelersInfo: DEFAULT_TRAVELERS_INFO,
  });

  const [uiState, setUiState] = useState({
    showTravelersModal: false,
    searching: false,
    errorMessage: '',
  });

  const today = useMemo(() => new Date().toISOString().split('T')[0], []);

  const isFormValid = useMemo(() => {
    const { originCode, destinationCode, departDate, returnDate, tripType } = formState;
    return !!(
      originCode &&
      destinationCode &&
      departDate &&
      (tripType === 'oneWay' || returnDate)
    );
  }, [formState]);

  useEffect(() => {
    const clearLocalStorage = () => {
      const keysToRemove = [
        'selectedArrivalFlight',
        'selectedDepartureFlight',
        'selectedFlight',
        'paymentInfo',
        'userData',
        'requestData'
      ];
      keysToRemove.forEach(key => localStorage.removeItem(key));
    };

    window.addEventListener('beforeunload', clearLocalStorage);
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
      clearLocalStorage();
    };
  }, []);

  const prepareRequestData = useCallback((isReturn = false) => {
    const { originCode, destinationCode, departDate, returnDate, travelersInfo } = formState;
    
    return {
      from: isReturn ? destinationCode : originCode,
      to: isReturn ? originCode : destinationCode,
      date: isReturn ? returnDate : departDate,
      adult: travelersInfo.adults,
      child: travelersInfo.children,
      infant: travelersInfo.infants,
      currency: 'USD',
    };
  }, [formState]);

  const handleSearch = useCallback(async () => {
    if (!isFormValid) return;

    setUiState(prev => ({ ...prev, searching: true, errorMessage: '' }));

    try {
      const requestData = prepareRequestData();
      localStorage.setItem('requestData', JSON.stringify(requestData));

      // Start both fetches simultaneously if it's a round trip
      const departureFetch = fetch(`${API_URL}?${new URLSearchParams(requestData)}`, API_OPTIONS);
      const returnFetch = formState.tripType === 'roundTrip' 
        ? fetch(`${API_URL}?${new URLSearchParams(prepareRequestData(true))}`, API_OPTIONS)
        : null;

      // Wait for all necessary fetches to complete
      const [departureResponse, returnResponse] = await Promise.all([departureFetch, returnFetch].filter(Boolean));

      if (!departureResponse.ok) throw new Error('Failed to fetch flight data');
      
      const departureData = await departureResponse.json();
      
      if (formState.tripType === 'roundTrip') {
        if (!returnResponse?.ok) throw new Error('Failed to fetch return flight data');
        const returnData = await returnResponse.json();

        // Navigate with loading state to avoid double loading animation
        navigate('/depart', {
          state: {
            searchData: requestData,
            results: departureData.results,
            returnResults: returnData.results,
            initialLoad: false // Signal that initial data is already loaded
          },
          replace: true
        });
      } else {
        navigate('/search_results', {
          state: {
            searchData: requestData,
            results: departureData.results,
            initialLoad: false // Signal that initial data is already loaded
          },
          replace: true
        });
      }
    } catch (error) {
      setUiState(prev => ({
        ...prev,
        errorMessage: 'Failed to fetch flight data. Please try again later.'
      }));
    } finally {
      setUiState(prev => ({ ...prev, searching: false }));
    }
  }, [formState, isFormValid, navigate, prepareRequestData]);

  const updateFormState = useCallback((field, value) => {
    setFormState(prev => ({ ...prev, [field]: value }));
  }, []);

  const updateTravelersInfo = useCallback((newInfo) => {
    setFormState(prev => ({ ...prev, travelersInfo: newInfo }));
    setUiState(prev => ({ ...prev, showTravelersModal: false }));
  }, []);

  return (
    <div className="booking-container">
      
      <div className="booking-wrapper">
          <div className="booking-title">Search , Compare , Book airline tickets with VGroup Ventures LLC</div>


<div className="home-call-class-content">
        <div className='booking-title'>Call now & Get up to 15 % off on flights</div>
        <a href="tel:+18664104797" className="home-call-class-button">
      Toll Free : +1(866)410-4797
        </a>
      </div>


        <div className="booking-card">
          <div className="booking-content">
            {/* Trip Type Selection */}
            <div className="trip-type">
              {['roundTrip', 'oneWay'].map(type => (
                <label key={type} className="radio-label">
                  <input
                    type="radio"
                    name="tripType"
                    value={type}
                    checked={formState.tripType === type}
                    onChange={e => updateFormState('tripType', e.target.value)}
                    disabled={uiState.searching}
                  />
                  {type === 'roundTrip' ? 'Round Trip' : 'One Way'}
                </label>
              ))}
            </div>

            {/* Main Search Form */}
            <div className="search-grid">
              <div className="input-group">
                <span className="input-icon">📍</span>
                <AirportAutocomplete
                  id="origin"
                  value={formState.originCode}
                  onChange={value => updateFormState('originCode', value)}
                  disabled={uiState.searching}
                />
              </div>

              <div className="input-group">
                <span className="input-icon">📍</span>
                <AirportAutocomplete
                  id="destination"
                  value={formState.destinationCode}
                  onChange={value => updateFormState('destinationCode', value)}
                  disabled={uiState.searching}
                />
              </div>

              <div className="date-group">
                <div className="input-group">
                  <span className="input-icon">📅</span>
                  <DateInput
                    id="departDate"
                    value={formState.departDate}
                    onChange={value => updateFormState('departDate', value)}
                    minDate={today}
                    label="Departure Date"
                    disabled={uiState.searching}
                  />
                </div>
                
                {formState.tripType === 'roundTrip' && (
                  <div className="input-group">
                    <span className="input-icon">📅</span>
                    <DateInput
                      id="returnDate"
                      value={formState.returnDate}
                      onChange={value => updateFormState('returnDate', value)}
                      minDate={formState.departDate || today}
                      label="Return Date"
                      disabled={uiState.searching}
                    />
                  </div>
                )}
              </div>

              <div className="action-group">
                <div className="input-group">
                  <span className="input-icon">👤</span>
                  <input
                    type="text"
                    className="search-input"
                    value={formState.travelersInfo.displayText}
                    onClick={() => !uiState.searching && setUiState(prev => ({ ...prev, showTravelersModal: true }))}
                    readOnly
                    disabled={uiState.searching}
                  />
                </div>

                <button
                  className="search-button"
                  onClick={handleSearch}
                  disabled={!isFormValid || uiState.searching}
                >
                  {uiState.searching ? 'Searching...' : 'Search'}
                </button>
              </div>
            </div>
          </div>
        </div>

        {uiState.errorMessage && (
          <div className="error-message">{uiState.errorMessage}</div>
        )}
      </div>

      {uiState.showTravelersModal && (
        <TravelersModal
          isOpen={true}
          onClose={() => setUiState(prev => ({ ...prev, showTravelersModal: false }))}
          onSave={updateTravelersInfo}
          initialValues={formState.travelersInfo}
        />
      )}
    </div>
  );
};

export default React.memo(Booking);
