import React from 'react';
import { FaPlane, FaUtensils, FaLaugh, FaConciergeBell } from 'react-icons/fa';
import './UpgradeOption.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import '@fortawesome/fontawesome-free/css/all.min.css';
// Sample images (replace with actual image URLs)
import bannerImage from '../../Media/defence.webp'
import premiumEconomyImage from '../../Media/premiumEconomyImage.jpg';
import businessClassImage from '../../Media/businessClassImage.jpg';
import firstClassImage from '../../Media/firstClassImage.jpg';
import NumberPopup from '../NumberPopup/NumberPopup';
import Bookingslanding from '../Bookings/Bookingslanding.jsx';
import callLand from '../../Media/call_land.jpg'
import image from '../../Media/fap.jpeg';

const IconText = ({ icon, text }) => (
  <div className="UpgradeOption-icon-text">
    {icon}
    <p>{text}</p>
  </div>
);

const UpgradeOption = ({ image, title, description }) => (
  <div className="UpgradeOption-upgrade-option">
    <img src={image} alt={title} />
    <h6>{title}</h6>
    <p>{description}</p>
  </div>
);

const options = [
  { label: 'Flight booking', phoneNumber: '(866) 410-4797' },
  { label: 'Flight change', phoneNumber: '(866) 410-4797' },
  { label: 'Flight upgrade', phoneNumber: '(866) 410-4797' },
  { label: 'Flight cancellation', phoneNumber: '(866) 410-4797' },
];

const handleClick = (phoneNumber) => {
  // Google Ads conversion tracking for phone calls
  window.gtag('config', 'AW-16610863777/TfD8CJex_70ZEKHV1vA9', {
    'phone_conversion_number': phoneNumber,
  });

  // Initiate the call
  window.location.href = `tel:${phoneNumber}`;
};


const handlePhoneClick = () => {
  // Google Ads conversion tracking for phone calls
  window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
    'phone_conversion_number': '(866) 410-4797'
  });

  // Initiate the phone call
  window.location.href = 'tel:+18664104797';
};



const FlightUpgradeComponent = () => (
<>
<div className="landing-page-1-container">
        <div className="landing-page-1-card">
          <h2 className="landing-page-1-title">Airline Reservation Desk </h2>
          <h2 className="landing-page-1-title">24/7 Helpline ( Toll-free )</h2>
          <img
            src={image} 
            alt="Flight Attendant"
            className="landing-page-1-image"
          />
          <button  className="landing-page-1-cta-button"  onClick={handlePhoneClick} >Booking, Changes & Cancellation</button>
          <div className="landing-page-1-call-info">
            <p>No Hold – Call Answered In 5 Sec</p>
            <a onClick={handlePhoneClick} className="landing-page-1-phone-number">
              <div className="landing-page-1-phone-box">
                {/* Animated Font Awesome Icon */}
                <FontAwesomeIcon
                  icon={faPhone}
                  className="landing-page-1-phone-icon fa-bounce" // Add bounce animation
                /> (866) 410-4797
              </div>
            </a>
            <p>24/7 Helpline</p>
          </div>
        </div>
      </div>


{/* <div className="booking-landing-number-banner-container">
      <h5 className="booking-landing-number-banner-title">Airline Reservation Desk </h5>
      <h5 className="booking-landing-number-banner-title">24/7 Helpline ( Toll-free ) </h5>
      <div className="booking-landing-number-banner-image">
        <img src={callLand} alt="call-land-banner" />
        Add an appropriate image here
      </div>
      <button className="booking-landing-number-banner-button"  href="tel:+18664104797" onClick={handlePhoneClick}>
<a  onClick={() => handlePhoneClick}   href="tel:+18664104797">Booking, Changes & Cancellation</a>
      </button>
      <div className="booking-landing-number-banner-helpline">
      <a  onClick={() => handlePhoneClick}   href="tel:+18664104797"><p className="booking-landing-number-banner-text">Speak to live travel expert for assistance</p></a>
      <p className="booking-landing-number-banner-text">No Hold - Call Answered in 5 Sec</p>
        <a  onClick={() => handlePhoneClick}   href="tel:+18664104797" className="booking-landing-number-banner-phone">(866) 410-4797</a>
        <a  onClick={() => handlePhoneClick}   href="tel:+1(866)4104797"><p className="booking-landing-number-banner-text">24/7 Helpline</p></a>
      </div>
    </div> */}
<Bookingslanding/>
<div className="UpgradeOption-container">
    <div className="UpgradeOption-banner" style={{ backgroundImage: `url(${bannerImage})` }}>
      <h1>Elevate Your Journey with Flight Upgrades from Vgroup Ventures LLC</h1>
    </div>
    <section>
      <h2>Experience Luxury and Comfort</h2>
      <p>
        At Vgroup Ventures LLC, we believe that your journey should be as enjoyable as your destination. That's why we offer a range of flight upgrade options to enhance your travel experience. Whether you’re looking for extra legroom, gourmet meals, or premium services, our flight upgrade service ensures you travel in style and comfort.
      </p>
    </section>
    <section>
      <h3>Why Upgrade with Vgroup Ventures LLC?</h3>
      <IconText icon={<FaPlane size={20} />} text="Exclusive Comfort: Enjoy spacious seating, priority boarding, and additional amenities that make your journey more relaxing and enjoyable." />
      <IconText icon={<FaConciergeBell size={20} />} text="Premium Services: Benefit from enhanced services such as dedicated check-in counters, access to exclusive lounges, and gourmet dining options." />
      <IconText icon={<FaLaugh size={20} />} text="Affordable Luxury: Take advantage of our competitive prices and exclusive deals on upgrades to make luxury travel more accessible." />
      <IconText icon={<FaUtensils size={20} />} text="Seamless Process: Our user-friendly platform makes it easy to upgrade your flight with just a few clicks, ensuring a hassle-free experience." />
    </section>
    <section>
      <h3>How It Works</h3>
      <p>1. Access Your Booking: Log in to your Vgroup Ventures LLC account and navigate to the "Manage Booking" section. Enter your booking reference number and the email address used for the reservation.</p>
      <p>2. Explore Upgrade Options: Browse the available upgrade options for your flight, including premium economy, business class, and first class. Review the features and benefits of each option.</p>
      <p>3. Select and Confirm: Choose the upgrade that best suits your needs and preferences. Confirm your selection and proceed to payment.</p>
      <p>4. Receive Updated Confirmation: Once your upgrade is processed, you will receive a new confirmation email with your updated travel details and information about your upgraded services.</p>
    </section>
    <section>
      <h3>Upgrade Options</h3>
      <div className="UpgradeOption-grid">
        <UpgradeOption image={premiumEconomyImage} title="Premium Economy" description="Enjoy more legroom, wider seats, and enhanced meal options." />
        <UpgradeOption image={businessClassImage} title="Business Class" description="Experience superior comfort with lie-flat seats, priority boarding, and access to business class lounges." />
        <UpgradeOption image={firstClassImage} title="First Class" description="Indulge in the ultimate luxury with private suites, gourmet dining, and personalized service." />
      </div>
    </section>
    <section>
      <h3>Benefits of Upgrading</h3>
      <p>• Enhanced Comfort: Relax in spacious and comfortable seating designed to provide maximum comfort during your flight.</p>
      <p>• Priority Services: Enjoy priority boarding, expedited security checks, and dedicated check-in counters for a seamless travel experience.</p>
      <p>• Exclusive Access: Gain access to premium airport lounges where you can relax, dine, and freshen up before your flight.</p>
      <p>• Gourmet Dining: Savor exquisite meals and beverages crafted by top chefs, tailored to your preferences.</p>
    </section>
    <section>
      <h3>Important Information</h3>
      <p>• Upgrade Availability: Upgrades are subject to availability and airline policies. We work closely with our airline partners to provide you with the best options.</p>
      <p>• Upgrade Fees: The cost of an upgrade varies depending on the airline, route, and class of service. All fees and charges will be transparently displayed before you confirm your upgrade.</p>
      <p>• Loyalty Programs: If you are a member of an airline loyalty program, you may be eligible to use your miles or points for upgrades.</p>
    </section>
    {/* <section>
      <h3>Stay Connected</h3>
      <p>
        Follow us on social media and subscribe to our newsletter for the latest updates, exclusive offers, and travel tips. Stay connected with the Vgroup Ventures LLC community to make the most of your travel experience.
      </p>
    </section>
    <section>
      <h3>Contact Us</h3>
      <p>
        If you need assistance with your flight upgrade, our dedicated support team is available 24/7 to help. Contact us through our website, email, or customer service hotline for prompt and friendly assistance.
      </p>
    </section>
    <div className="UpgradeOption-cta">
      <button>Upgrade Your Travel Experience with Vgroup Ventures LLC</button>
    </div> */}

    <NumberPopup/>
  </div>
</>
);

export default FlightUpgradeComponent;
